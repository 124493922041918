import IMask from 'imask';
import { isPlainObject } from 'lodash';
import {
  cpfMasks,
  dateMasks,
  kmMask,
  phoneMasks,
  phoneMasksPt,
  plateMasks,
  priceMask,
  priceWithoutThousandsSeparatorMask,
  yearMask,
} from '%/utils/masks';

export const MASK_TYPES = {
  CPF: 'CPF',
  PHONE: 'PHONE',
  PHONE_PT: 'PHONE_PT',
  DATE: 'DATE',
  PLATE: 'PLATE',
  KM: 'KM',
  PRICE: 'PRICE',
  PRICE_WITHOUT_SEPARATOR: 'PRICE_WITHOUT_SEPARATOR'
};

export const MASKS = {
  [MASK_TYPES.CPF]: cpfMasks,
  [MASK_TYPES.PHONE]: phoneMasks,
  [MASK_TYPES.PHONE_PT]: phoneMasksPt,
  [MASK_TYPES.DATE]: dateMasks,
  [MASK_TYPES.PLATE]: plateMasks,
  [MASK_TYPES.KM]: kmMask,
  [MASK_TYPES.PRICE]: priceMask,
  [MASK_TYPES.PRICE_WITHOUT_SEPARATOR]: priceWithoutThousandsSeparatorMask,
  [MASK_TYPES.YEAR]: yearMask,
};

/**
 * Mascara um campo.
 * @param {string} type O tipo da máscara a ser aplicada no campo
 * @param {DOMNode} field O nó a ser mascarado
 * @param {object} options Opções extra para o plugin
 * @param {object} callback Função sempre chamada após atualização do valor
 */
export default (type, field, options, callback) => {
  if (!MASKS[type]) {
    throw 'Invalid mask exception';
  }

  let iMaskConfig = {
    ...options
  };

  if (MASKS[type] instanceof Array) {
    iMaskConfig['mask'] = MASKS[type];
  } else if (isPlainObject(MASKS[type])) {
    iMaskConfig = { ...iMaskConfig, ...MASKS[type] };
  }

  const mask = IMask(field, iMaskConfig);

  if (callback) {
    mask.on('accept', () => {
      callback(mask.value);
    });
  }
};
