import { h, Component } from 'preact';
import PartConversionForm from './PartConversionForm';
import PropTypes from 'prop-types';

class PartConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
    };

    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCloseOverlay(e) {
    this.setState({ showOverlay: false });
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true });
    return serviceConversion
      .convert(params)
      .then(response => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false,
        });
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true,
        });
        console.error(error);
      })
      .then(() => {
        this.setState({
          showOverlay: true,
        });
      });
  }

  render() {
    const { isSubmittingForm, errorSendingForm, showOverlay } = this.state;
    const { formId, mainPhrase } = this.props;

    return (
      <PartConversionForm
        formId={formId}
        isSubmittingForm={isSubmittingForm}
        errorSendingForm={errorSendingForm}
        showOverlay={showOverlay}
        handleSubmit={this.handleSubmit}
        handleCloseOverlay={this.handleCloseOverlay}
        mainPhrase={mainPhrase}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={this.props.units}
        filterUnitsByLocation={this.props.filterUnitsByLocation}
        product={this.props.product}
        channel={this.props.channel}
        category={this.props.category}
        brand={this.props.brand}
        showCpf={this.props.showCpf}
        showUnits={this.props.showUnits}
        showLocationFields={this.props.showLocationFields}
        showLabels={this.props.showLabels}
        versions={this.props.versions}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        showCustomerType={this.props.showCustomerType}
      />
    );
  }
}

PartConversionFormApp.defaultProps = {
  mainPhrase:
    'Preencha o formulário abaixo para receber o contato de um de nossos especialistas:',
  units: [],
  filterUnitsByLocation: false,
  brand: '-',
  showUnits: true,
  shouldShowDataPermissions: false,
  showLabels: false,
  showCpf: false,
  versions: [],
  showLocationFields: false,
  showCustomerType: false,
};

PartConversionFormApp.propTypes = {
  formId: PropTypes.string.isRequired,
  mainPhrase: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  unit: PropTypes.string,
  filterUnitsByLocation: PropTypes.bool,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  showUnits: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  showLabels: PropTypes.bool,
  showCpf: PropTypes.bool,
  showCustomerType: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  showLocationFields: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};

export default PartConversionFormApp;
