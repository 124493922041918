// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Input extends Component {
  render() {
    const {
      customClasses,
      showLabels,
      title,
      name,
      value,
      type,
      minLength,
      maxLength,
      autoComplete,
      required,
      placeholder,
      onChange,
      onBlur,
      onKeyUp,
      onInput,
      setRef,
    } = this.props;

    return (
      <div className={classNames('form__text-field', customClasses)}>
        {showLabels && (
          <label className="form__text-field-label" htmlFor={name}>
            {title}
          </label>
        )}
        <input
          className="form__text-field-input form__text-field-common"
          name={name}
          value={value}
          type={type}
          required={required}
          placeholder={placeholder}
          onChange={onChange}
          onInput={onInput}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          ref={setRef}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete}
          data-bouncer-target={`#invalid-${name}`}
        />
        <div id={`invalid-${name}`} className="invalid-feedback" />
      </div>
    );
  }
}

Input.propTypes = {
  customClasses: PropTypes.string,
  showLabels: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  setRef: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.string,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
};
