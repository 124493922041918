export function cpfMask(value) {
  let formattedValue = value;

  formattedValue = formattedValue.replace(/\D/g, '');

  formattedValue = formattedValue.slice(0, 11);

  formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
  formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
  formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return formattedValue;
}
