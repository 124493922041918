import { h } from 'preact';
import { Component } from 'react';

export default class SubmitButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let finalClasses = this.props.classes,
      buttonContent;

    if (this.props.isSubmitting) {
      finalClasses += ` ${this.props.submittingClass}`;
      buttonContent = (
        <div>
          {this.props.submittingLabel}
          <div className="ml-2 spinner-border" role="status">
            <span className="sr-only">Enviando...</span>
          </div>
        </div>
      );
    } else {
      buttonContent = this.props.label;
    }

    return (
      <button
        disabled={this.props.isSubmitting}
        className={finalClasses}
        onClick={this.props.handleClick}
        type='submit'
      >
        {buttonContent}
      </button>
    );
  }
}

SubmitButton.defaultProps = {
  isSubmitting: false,
  classes: 'btn button button--large button--primary w-100 mb-0',
  submittingClass: 'is-submitting',
  label: 'Enviar',
  submittingLabel: 'Enviando...',
  handleClick: () => {}
};
