import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import CustomSelect from './CustomSelect';
import CustomCheck from './CustomCheck';
import FormMessageOverlay from './FormMessageOverlay';
import SubmitButton from './SubmitButton';
import VariantCollection, {
  variantObjectsToCustomSelectOptions,
} from '%/utils/variantCollection';
import getUTM from '%/utils/utm';
import handleInputChange from '%/utils/handleInputChange';
import ProfileImageTypes from '%/utils/variantProfileImageTypes';
import validationFactory from '%/utils/validation';
import upperCaseAndRemoveAccent from '%/utils/upperCaseAndRemoveAccent';
import IMask from 'imask';
import { phoneMasks } from '%/utils/masks';
import { PERSON_TYPES } from '%/utils/personTypes';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import InputCpf from './InputCpf';
import InputCnpj from './InputCnpj';
import LocationFields from './Inputs/Location';
import ZFlow from './ZFlow';

/**
 * @property {VariantCollection} variantsCollection
 */
export default class ConversionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVariant: this.props.defaultVariant,
      name: null,
      email: null,
      phone: null,
      'veiculo-na-troca': 'nao',
      phoning: false,
      mailing: false,
      whatsapping: false,
      contactOptions: '',
      dataPermissions: false,
      cpf: '',
      cnpj: '',
      customerType: PERSON_TYPES.INDIVIDUAL,
      model_car: '',
      versionValidStatus: true,
      uf: null,
      city: null,
      units: this.props.units,
      subsidiaries: this.props.subsidiaries,
      subsidiary: '',
      subsidiaryDisabled: false,
    };

    this.getFormData = this.getFormData.bind(this);
    this.getCurrentVariantItem = this.getCurrentVariantItem.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVariantChange = this.handleVariantChange.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleFandiButton = this.handleFandiButton.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);

    this.watchStore();
  }

  watchStore() {
    window.store.vehicleVersionDetails.watch(({ activeVersion }) => {
      if (activeVersion && this.getCurrentVariantItem()) {
        activeVersion && this.setState({ selectedVariant: activeVersion.slug });
      }
    });
  }

  handleVariantChange(e) {
    this.handleInputChange(e);

    const currentVariantItem = this.getCurrentVariantItem();

    if (currentVariantItem) {
      if (this.props.shouldShowVariantSelect) {
        this.historyPushState();
      }
      this.props.handleVariantChange(currentVariantItem);
    }
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf, subsidiary: '', subsidiaries: [] });
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({ city, subsidiary: '' });

    if (this.props.filterUnitsByLocation && this.state.uf && this.state.city) {
      this.setState({ subsidiaryDisabled: true });

      let apiQuery = [
        { 'q[state_cont]': this.state.uf },
        { 'q[city_cont]': this.state.city },
      ];

      const categoryUpperCase = upperCaseAndRemoveAccent(
        this.props.modelCategory,
      );

      if (categoryUpperCase == 'ONIBUS') {
        apiQuery.push({ 'q[bus_eq]': true });
      } else {
        apiQuery.push({ 'q[truck_eq]': true });
      }

      window.autoServiceApi.getServedRegion(apiQuery).then(res => {
        let subsidiaries = res.entries.map(region => ({
          label: region.display_name,
          value: region.display_name,
          city: region.city,
        }));

        let subsidiariesUniq = this.uniqByKeepFirst(
          subsidiaries,
          sub => sub.label,
        );

        // TODO atualizar filtro do apicasso para remover nomes parciais, ex: SP cidade Arujá que retorna Guarujá.
        subsidiariesUniq = subsidiariesUniq.filter(
          s => s.city.length == this.state.city.length,
        );

        this.setState({
          subsidiaries: subsidiariesUniq,
          subsidiary: '',
          subsidiaryDisabled: false,
        });
      });
    }
  }

  uniqByKeepFirst(a, key) {
    let seen = new Set();
    return a.filter(item => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  historyPushState() {
    if (window.history.pushState)
      window.history.pushState({}, null, this.getUrlVersion());
  }

  handleFandiButton(e) {
    e.preventDefault();

    window.dispatchEvent(
      new CustomEvent('OpenFandi', { detail: this.getFormData() }),
    );
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  /**
   * Retorna a url já com a versão selecionada para ser setada no history
   */
  getUrlVersion() {
    const href = window.location.pathname,
      slugVersion = this.getSlugVersion(),
      currentVersion = this.getCurrentVariantItem(),
      newSlugVersion = currentVersion.getVariantProperty('slug');

    const newPathname = href
      .replace(/\/$/, '')
      .replace(slugVersion, '')
      .replace(/\/$/, '');

    return `${window.location.origin}${newPathname}/${newSlugVersion}${window.location.search}`;
  }

  /**
   * Retorna o slug da versão a parti da url
   */
  getSlugVersion() {
    const matches = window.location.pathname.match(/(novo|ofertas).+$/);
    if (matches && matches.length > 0) {
      const pathItems = matches[0].split('/');
      if (pathItems.length > 2) {
        return pathItems[2].replace(/\//g, '');
      } else if (pathItems.length == 2 && pathItems[0] == 'ofertas') {
        return pathItems[1].replace(/\//g, '');
      }
    }
    return '';
  }

  /**
   * Retorna a posicação no array das variants(versões) a parti do slug da versão
   */
  getIndexBySlugVersion() {
    const slugVersion = this.getSlugVersion();
    const index = this.props.variants.reduce((acc, variant, index) => {
      if (
        variant.slug === slugVersion ||
        (typeof offer_slug !== 'undefined' && offer_slug == variant.slug)
      ) {
        acc = index;
      }
      return acc;
    }, 0);

    return index;
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf);
  }

  versionValid() {
    return this.state.model_car;
  }

  validateLocationFields() {
    if (!this.state.city) {
      document
        .getElementsByName('city')[0]
        .parentElement.classList.add('is-invalid');
      if (!this.state.uf) {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.add('is-invalid');
      } else {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.remove('is-invalid');
      }
    }
  }

  validateSubsidiary() {
    const elSubsidiarySelector = document.querySelector(
      '.subsidiary-select .choices__inner',
    );
    elSubsidiarySelector.classList.toggle('is-invalid', !this.state.subsidiary);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ versionValidStatus: true });

    if (this.props.filterUnitsByLocation && this.props.showLocationFields)
      this.validateSubsidiary();

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;
    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return;
      }
    }

    if (this.props.showLocationFields) this.validateLocationFields();

    if (!formIsValid) {
      return;
    }
    if (
      !this.cpfValid() &&
      this.state.customerType === PERSON_TYPES.INDIVIDUAL
    ) {
      alert('O campo de CPF é obrigatório');
      return;
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData());
  }

  componentDidMount() {
    const phoneMask = IMask(this.phoneInput, {
      mask: [...phoneMasks],
    });
    phoneMask.on('accept', () => this.setState({ phone: phoneMask.value }));
    this.validator = validationFactory(`#${this.props.formId}`);
  }

  /**
   * Retorna o valor configurado pela prop `brand`.
   * Caso nenhum valor esteja informado para essa prop, retorna a marca da
   * variante atualmente selecionada.
   * Se nenhuma das condições acima forem cumpridas, retorna a marca do primeiro
   * item da coleção de variantes.
   *
   * @return {string}
   */
  getCurrentItemBrand() {
    let result = this.props.brand;

    if (result) {
      return result;
    }

    let currentVariantItem = this.getCurrentVariantItem();
    if (this.getCurrentVariantItem()) {
      return currentVariantItem.getVariantProperty('brand');
    }

    return (
      (this.variantsCollection &&
        this.variantsCollection._variants &&
        this.variantsCollection._variants.length > 0 &&
        this.variantsCollection._variants[0]) ||
      ''
    );
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      version: this.props.defaultVariant || this.state.selectedVariant,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      mailing: this.state.mailing,
      phoning: this.state.phoning,
      whatsapping: this.state.whatsapping,
      'veiculo-na-troca': this.state['veiculo-na-troca'],
      model_car: this.state.model_car,
      customerType: this.state.customerType,
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf;
    }

    if (this.props.showCustomerType) {
      state.customerType = this.state.customerType;

      if (this.state.customerType === PERSON_TYPES.COMPANY) {
        state.cnpj = this.state.cnpj;
      }
    }

    if (this.props.showLastName) {
      state.last_name = this.state.last_name;
    }

    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions;
    }

    if (this.props.showLocationFields) {
      state.uf = this.state.uf;
      state.city = this.state.city;
    }

    if (this.state.subsidiary) {
      state.subsidiary = this.state.subsidiary;
    }

    let props = {
      product: this.props.product,
      bait: -5,
      channel: this.props.channel,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM(),
    };

    if (state['version']) {
      props['product'] += ` ${this.getCurrentVariantItem().getVariantProperty(
        'name',
      )}`;
    }

    if (this.props.cloneId) {
      props['product_id'] = this.props.cloneId;
    }

    if (this.props.model_year) {
      props['model_year'] = this.props.model_year;
    }

    if (this.props.unit) {
      props['unit'] = this.props.unit;
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value;
    } else {
      props['unit'] = this.state.unit;
    }

    this.props.customFields.map(field => (props[field.name] = field.value));

    return { ...state, ...props };
  }

  componentWillUpdate() {
    this.variantsCollection = new VariantCollection(this.props.variants);
  }

  /**
   * Se houver apenas uma variante, não precisamos exibir o select, apenas
   * manter a primeira opção selecionada. Essa função trata isso.
   * @return {boolean}
   */
  shouldDisplayCustomSelect() {
    return this.props.shouldShowVariantSelect && this.props.variants.length > 1;
  }

  /**
   * Converte a prop de variantes para o formato utilizado no custom select:
   * { value: "foo", name: "bar" }
   */
  variantOptionsToCustomSelect() {
    return variantObjectsToCustomSelectOptions(
      this.props.variants,
      'slug',
      'name',
    );
  }

  /**
   * Obtém a foto da variante atualmente selecionada.
   * @return {string}
   */
  getCurrentVariantPhotoUrl() {
    return (
      this.getCurrentVariantItem() &&
      this.getCurrentVariantItem().getProfileImageOfType(
        ProfileImageTypes.MODEL_MIDDLE,
      )
    );
  }

  /**
   * Obtém o item da variante atualmente escolhido
   * @return {VariantItem|null}
   */
  getCurrentVariantItem() {
    const selectedVariant =
      this.props.defaultVariant || this.state.selectedVariant;
    return (
      selectedVariant &&
      this.variantsCollection &&
      this.variantsCollection.findVariantBy('slug', selectedVariant)
    );
  }

  getCurrentVariantItemPrice() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return selectedVariantItem && selectedVariantItem.price;
  }

  getCurrentVariantItemName() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return selectedVariantItem && selectedVariantItem._variant.name;
  }

  /**
   * Indica se o preço deve ser exibido
   * @return {bool}
   */
  shouldShowPrice() {
    return this.props.shouldShowPrice && !!this.getCurrentVariantItemPrice();
  }

  /**
   * Indica se o nome da versão deve ser exibido
   * @return {bool}
   */
  shouldShowVariantName() {
    return (
      this.props.shouldShowVariantName && !!this.getCurrentVariantItemName()
    );
  }

  /**
   * Indica se a foto deve ser exibida
   * @return {bool}
   */
  shouldShowImage() {
    return this.props.shouldShowImage && !!this.getCurrentVariantPhotoUrl();
  }

  getSelectedVariant() {
    if (this.state.selectedVariant) {
      return this.state.selectedVariant;
    } else {
      if (this.variantOptionsToCustomSelect().length > 0) {
        const index = this.getIndexBySlugVersion();
        window.store.vehicleVersionDetails.setState({
          activeVersion: this.props.variants[index],
        });
        return this.variantOptionsToCustomSelect()[index].value;
      } else {
        return '';
      }
    }
  }

  render() {
    const {
      className,
      titleForm,
      showLastName,
      showFandiButton,
      showLabels,
      mainPhrase,
      showLocationFields,
    } = this.props;

    const customerTypeOptions = [
      { label: 'Pessoa física', value: PERSON_TYPES.INDIVIDUAL },
      { label: 'Pessoa jurídica', value: PERSON_TYPES.COMPANY },
    ];

    const isPessoaFisica =
      this.props.showCustomerType &&
      this.state.customerType === PERSON_TYPES.INDIVIDUAL;
    const isPessoaJuridica =
      this.props.showCustomerType &&
      this.state.customerType === PERSON_TYPES.COMPANY;
    const shouldShowCpfOnly =
      !this.props.showCustomerType && this.props.showCpf;

    return (
      <form
        className={classNames('conversion-form', className)}
        ref={form => (this.form = form)}
        id={this.props.formId}
        onSubmit={this.handleSubmit}
        novalidate
      >
        <FormMessageOverlay
          handleClose={this.props.handleCloseOverlay}
          isVisible={this.props.showOverlay}
          type={this.props.errorSendingForm ? 'error' : 'success'}
        />
        <header>
          {(titleForm || mainPhrase) && (
            <div className="conversion-form__header-phrase">
              {titleForm && (
                <h2 className="form-conversion__title">{titleForm}</h2>
              )}
              {mainPhrase && <p>{mainPhrase}</p>}
            </div>
          )}
          {this.shouldShowImage() && (
            <div className="conversion-form__photo">
              <LazyLoad>
                <img
                  src={this.getCurrentVariantPhotoUrl()}
                  alt={
                    this.getCurrentVariantItem() &&
                    this.getCurrentVariantItem().getVariantProperty('name', '')
                  }
                  width={142}
                  height={100}
                />
              </LazyLoad>
            </div>
          )}
          {this.shouldShowVariantName() && (
            <p className="conversion-form__variant-name">
              {this.getCurrentVariantItemName()}
            </p>
          )}
          {this.shouldShowPrice() && (
            <p className="conversion-form__price">
              A partir de <span>R$ {this.getCurrentVariantItemPrice()}</span>
            </p>
          )}
        </header>
        <div className="form-conversion__body">
          <fieldset>
            {this.props.showCustomerType && (
              <div className="form-group">
                {showLabels && (
                  <label className="conversion-form__control-label">
                    Tipo de cliente
                  </label>
                )}
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="customerType"
                  options={customerTypeOptions}
                  value={this.state.customerType}
                  placeholder={false}
                  shouldSort={false}
                  searchEnabled={false}
                />
              </div>
            )}
            <div
              className={classNames('form-group', {
                'd-none': !this.shouldDisplayCustomSelect(),
              })}
            >
              {showLabels && <label htmlFor="selectedVariant">Modelo</label>}
              <CustomSelect
                id="select-variants"
                name="selectedVariant"
                handleSelectChange={this.handleVariantChange}
                options={this.variantOptionsToCustomSelect()}
                value={this.getSelectedVariant()}
                placeholder={false}
                removeItemButton={false}
              />
            </div>
            <div className="form-group">
              {showLabels && <label htmlFor="name">Nome</label>}
              <input
                onChange={this.handleInputChange}
                value={this.state.name}
                type="text"
                className="form-control"
                name="name"
                required
                placeholder="Nome"
                maxLength={80}
                data-bouncer-target="#invalid-name"
              />
              <div id="invalid-name" className="invalid-feedback" />
            </div>

            {showLastName && (
              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={this.state.last_name}
                  type="text"
                  className="form-control"
                  name="last_name"
                  required
                  placeholder="Sobrenome"
                  data-bouncer-target="#invalid-last-name"
                />
                <div id="invalid-last-name" className="invalid-feedback" />
              </div>
            )}

            <div className="form-group">
              {showLabels && <label htmlFor="email">E-mail</label>}
              <input
                onChange={this.handleInputChange}
                value={this.state.email}
                type="email"
                required={
                  this.props.contactsWithDynamicRequired
                    ? this.state.mailing
                    : true
                }
                className="form-control"
                name="email"
                placeholder="E-mail"
                maxLength={80}
                data-bouncer-target="#invalid-email"
              />
              <div id="invalid-email" className="invalid-feedback" />
            </div>
            <div className="form-group">
              {showLabels && <label htmlFor="phone">Telefone</label>}
              <input
                onChange={this.handleInputChange}
                value={this.state.phone}
                ref={phoneInput => (this.phoneInput = phoneInput)}
                className="form-control"
                name="phone"
                required={
                  this.props.contactsWithDynamicRequired
                    ? this.state.phoning || this.state.whatsapping
                    : true
                }
                type="phone"
                data-bouncer-target="#invalid-phone"
                autoComplete="off"
                placeholder="Telefone/Whatsapp"
                data-should-validate={
                  this.props.contactsWithDynamicRequired
                    ? String(this.state.phoning || this.state.whatsapping)
                    : 'true'
                }
              />
              <div id="invalid-phone" className="invalid-feedback" />
            </div>

            {isPessoaFisica && (
              <InputCpf
                handleChange={this.handleInputChange}
                value={this.state.cpf}
                label={showLabels ? 'CPF' : ''}
              />
            )}

            {isPessoaJuridica && (
              <InputCnpj
                handleChange={this.handleInputChange}
                value={this.state.cnpj}
                label={showLabels ? 'CNPJ' : ''}
              />
            )}

            {shouldShowCpfOnly && (
              <InputCpf
                handleChange={this.handleInputChange}
                value={this.state.cpf}
                label={showLabels ? 'CPF' : ''}
              />
            )}

            {showLocationFields && (
              <LocationFields
                handleStateChange={this.handleStateChange}
                uf={this.state.uf}
                handleCityChange={this.handleCityChange}
                city={this.state.city}
                citiesByState={this.props.citiesByState}
              />
            )}

            {this.props.versions && this.props.versions.length > 0 && (
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="model_car"
                  options={this.props.versions}
                  value={this.state.model_car}
                  shouldSort={false}
                  placeholderValue="Escolha um modelo"
                  searchEnabled={true}
                />

                {!this.state.versionValidStatus && (
                  <div
                    id="invalid-version"
                    className="invalid-feedback is-invalid-version"
                  >
                    <div className="error-message" id="bouncer-error_version">
                      Por favor, selecione esse campo
                    </div>
                  </div>
                )}
              </div>
            )}

            {!this.props.unit &&
              this.props.units &&
              this.props.units.length > 1 &&
              this.props.showUnits && (
                <div className="form-group">
                  <label className="conversion-form__control-label">
                    Escolha a unidade:
                  </label>
                  <CustomSelect
                    handleSelectChange={this.handleInputChange}
                    name="unit"
                    options={this.props.units}
                    value={this.state.unit}
                    shouldSort={false}
                    placeholderValue="Selecione uma unidade"
                    searchEnabled={false}
                  />
                </div>
              )}

            {this.props.filterUnitsByLocation && showLocationFields && (
              <div className="form-group subsidiary-select">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="subsidiary"
                  options={this.state.subsidiaries}
                  value={this.state.subsidiary}
                  shouldSort={false}
                  placeholderValue="Selecione sua concessionária"
                  searchEnabled={false}
                  disable={this.state.subsidiaryDisabled}
                />
              </div>
            )}
            {this.props.filterUnitsByLocation && showLocationFields && (
              <div className="form-group subsidiary-options">
                <input
                  type="text"
                  className="form-control d-none"
                  name="subsidiary-options"
                  required
                  placeholder="Opções de Consessionaria"
                  data-bouncer-target="#invalid-subsidiary-options"
                  value={this.state.subsidiary}
                  checked={this.state.subsidiary}
                />
                <div
                  id="invalid-subsidiary-options"
                  className="invalid-feedback"
                />
              </div>
            )}
          </fieldset>
          {this.props.zflow && (
            <ZFlow
              isSubmitting={this.props.isSubmittingForm}
              getFormData={this.getFormData}
              getCurrentVariantItem={this.getCurrentVariantItem}
              defaultVariant={this.props.defaultVariant}
              checkStyle={this.props.checkStyle}
            />
          )}
          {this.props.showVehicleExchange && (
            <fieldset>
              <legend className="conversion-form__control-label">
                Veículo na troca?
              </legend>
              <div className="form-check form-check-inline">
                <CustomCheck
                  name="veiculo-na-troca"
                  value="sim"
                  isChecked={this.state['veiculo-na-troca'] === 'sim'}
                  onChangeCheckable={this.handleInputChange}
                  type="radio"
                  checkStyle={this.props.checkStyle}
                >
                  Sim
                </CustomCheck>
                <CustomCheck
                  name="veiculo-na-troca"
                  value="nao"
                  isChecked={this.state['veiculo-na-troca'] === 'nao'}
                  onChangeCheckable={this.handleInputChange}
                  type="radio"
                  checkStyle={this.props.checkStyle}
                >
                  Não
                </CustomCheck>
              </div>
            </fieldset>
          )}
          <fieldset>
            <legend className="conversion-form__control-label">
              Quero receber contato por:
            </legend>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="mailing"
                value="true"
                onChangeCheckable={this.handleContactOptionsChange}
                isChecked={this.state.mailing}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                E-mail
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="whatsapping"
                value="true"
                isChecked={this.state.whatsapping}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Whatsapp
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="phoning"
                value="true"
                isChecked={this.state.phoning}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Telefone
              </CustomCheck>
            </div>
            <div className="form-group contact-options">
              <input
                type="text"
                className="form-control d-none"
                name="contact-options"
                required
                placeholder="Opções de contato"
                data-bouncer-target="#invalid-contact-options"
                value={this.state.contactOptions}
                checked={this.state.contactOptions}
              />
              <div id="invalid-contact-options" className="invalid-feedback" />
            </div>
          </fieldset>
          {this.props.shouldShowDataPermissions && (
            <fieldset className="mt-0 mb-2">
              <div className="form-check form-check-inline conversion-form__data-permissions-field">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={this.state.dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                  checkStyle={this.props.checkStyle}
                >
                  {this.props.dataPermissionsCustomText}
                </CustomCheck>
              </div>
            </fieldset>
          )}
          <footer className="form-conversion__footer pt-1">
            {this.props.dataPrivacyPolicyCustomText}
            <a target="_blank" href={this.props.linkPrivacyPolicy}>
              Política de privacidade
            </a>
            .
          </footer>
          <hr className="my-3"></hr>
          <SubmitButton
            classes={classNames(
              'btn button button--large button--primary w-100',
              {
                'mb-3': !!showFandiButton,
                'mb-0': !showFandiButton,
              },
            )}
            label={this.props.buttonLabel}
            isSubmitting={this.props.isSubmittingForm}
            handleClick={this.handleSubmit}
          />
          {showFandiButton && (
            <a
              href="#"
              className="btn button button--large button--fandi w-100 mb-0"
              onClick={this.handleFandiButton}
            >
              Simular Financiamento
            </a>
          )}
        </div>
      </form>
    );
  }
}

// default props
ConversionForm.defaultProps = {
  titleForm: 'Estou Interessado',
  buttonLabel: 'ESTOU INTERESSADO',
  handleSubmit: function () {},
  handleCloseOverlay: function () {},
  handleVariantChange: function () {},
  handleContactOptionsChange: function () {},
  isSubmittingForm: false,
  errorSendingForm: false,
  shouldShowImage: true,
  shouldShowPrice: true,
  shouldShowVariantSelect: true,
  showLocationFields: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  shouldShowVariantName: false,
  showCustomerType: false,
  variants: [],
  // defaultVariant representa o valor de variante selecionado
  // Esse valor terá precedência sobre o valor selecionado pelo CustomSelect,
  // portanto, cuidado.
  defaultVariant: null,
  // Props relacionadas a conversão. Elas estão aqui porque não mudam.
  unit: '',
  units: [],
  showUnits: true,
  product: '',
  origin: '',
  channel: '',
  category: '',
  brand: null,
  bait: '',
  utmz: '',
  showCpf: false,
  showLastName: false,
  zflow: false,
  versions: [],
  showLabels: false,
  showVehicleExchange: true,
  customFields: [],
  showFandiButton: false,
  privacyPolicyStyle: 'link',
  channelName: 'Auto Dealer',
  subsidiaries: [],
  subsidiary: '',
  dataPrivacyPolicyCustomText: 'Ao informar meus dados, eu concordo com a ',
};

// props config
ConversionForm.propTypes = {
  titleForm: PropTypes.string,
  buttonLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  handleVariantChange: PropTypes.func,
  handleContactOptionsChange: PropTypes.func,
  variants: PropTypes.array.isRequired,
  mainPhrase: PropTypes.string,
  // Necessário para o Bouncer validar o form.
  formId: PropTypes.string.isRequired,
  isSubmittingForm: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  showOverlay: PropTypes.bool,
  shouldShowImage: PropTypes.bool.isRequired,
  shouldShowPrice: PropTypes.bool.isRequired,
  shouldShowVariantSelect: PropTypes.bool.isRequired,
  showLocationFields: PropTypes.bool,
  shouldShowVariantName: PropTypes.bool,
  showCustomerType: PropTypes.bool,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  defaultVariant: PropTypes.string,
  bait: PropTypes.string,
  brand: PropTypes.string,
  category: PropTypes.string,
  channel: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  origin: PropTypes.string,
  product: PropTypes.string,
  dataPermissionsCustomText: PropTypes.string,
  dataPrivacyPolicyCustomText: PropTypes.string,
  unit: PropTypes.string,
  filterUnitsByLocation: PropTypes.bool,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  checkStyle: PropTypes.oneOf(['dark', 'light']),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLastName: PropTypes.bool,
  showFandiButton: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  showLabels: PropTypes.bool,
  showVehicleExchange: PropTypes.bool,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  privacyPolicyStyle: PropTypes.oneOf(['check', 'link']),
  channelName: PropTypes.string,
  subsidiaries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  subsidiary: PropTypes.string,
};
