import { h } from 'preact';
import { Component } from 'react';
import maskField, { MASK_TYPES } from '%/utils/maskField';
import { phoneMasks } from '%/utils/masks';
import { PERSON_TYPES } from '%/utils/personTypes';
import classNames from 'classnames';
import FormMessageOverlay from './FormMessageOverlay';
import formToObj from 'form-to-obj';
import getUTM from '%/utils/utm';
import handleInputChange from '%/utils/handleInputChange';
import IMask from 'imask';
import PropTypes from 'prop-types';
import StaticForm from './StaticForm';
import SubmitButton from './SubmitButton';
import validationFactory from '%/utils/validation';
import CustomCheck from './CustomCheck';
import CustomSelect from './CustomSelect';
import InputFile from './InputFile';
import InputCpf from './InputCpf';
import InputCnpj from './InputCnpj';
import LocationFields from './Inputs/Location';
import { handleInputFileChange, resetInputFile } from '%/utils/uploadFile';

export default class StaticConversionForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      files: [],
      sendingFile: false,
      uploadError: false,
      name: null,
      email: null,
      phone: null,
      phoning: false,
      mailing: false,
      whatsapping: false,
      contactOptions: '',
      category: this.props.category,
      cpf: '',
      cnpj: '',
      customerType: PERSON_TYPES.INDIVIDUAL,
      versionValidStatus: true,
      dataPermissions: false,
      uf: null,
      city: null,
    };

    this.state = this.initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);

    this.handleInputFileChange = handleInputFileChange.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.resetInputFile = resetInputFile.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf });
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({ city });
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf);
  }

  versionValid() {
    return this.state.model_car;
  }

  validateLocationFields() {
    if (!this.state.city) {
      document
        .getElementsByName('city')[0]
        .parentElement.classList.add('is-invalid');
      if (!this.state.uf) {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.add('is-invalid');
      } else {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.remove('is-invalid');
      }
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;
    this.setState({ versionValidStatus: true });

    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return;
      }
    }

    if (this.props.showLocationFields) this.validateLocationFields();

    if (!formIsValid) {
      return;
    }

    if (
      !this.cpfValid() &&
      this.state.customerType === PERSON_TYPES.INDIVIDUAL
    ) {
      alert('O campo de CPF é obrigatório');
      return;
    }

    this.props.onStartSubmit();

    let params;

    try {
      params = await this.getFormData();
    } catch (err) {
      console.error(err);
      this.props.onStopSubmit();
      this.props.onErrorSubmit(true);
      this.props.onShowOverlay(true);
      return;
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, params).then(() => {
      this.setState({ ...this.initialState });
      this.resetInputFile();
    });
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  async getFormData() {
    let state = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      mailing: this.state.mailing,
      phoning: this.state.phoning,
      unit: this.state.unit,
      whatsapping: this.state.whatsapping,
      category: this.state.category,
      model_car: this.state.model_car,
      customerType: this.state.customerType,
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf;
    }

    if (this.props.showCustomerType) {
      state.customerType = this.state.customerType;

      if (this.state.customerType === PERSON_TYPES.COMPANY) {
        state.cnpj = this.state.cnpj;
      }
    }

    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions;
    }

    if (this.props.showLocationFields) {
      state.uf = this.state.uf;
      state.city = this.state.city;
    }

    if (this.uploadPromise) {
      const url = await this.uploadPromise;
      if (url) {
        state.attachment_url = url;
      } else {
        throw new Error('API não retornou a url do arquivo');
      }
    }

    let props = {
      product: this.props.product,
      bait: this.props.bait,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM(),
    };

    if (this.props.unit) {
      props['unit'] = this.props.unit;
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value;
    } else {
      props['unit'] = this.state.unit;
    }

    let staticInputsValues = {
      ...formToObj(this.form.querySelector('.form-conversion__custom-fields')),
    };

    return { ...state, ...props, ...staticInputsValues };
  }

  /**
   * Efetua a preparação dos campos para comportar as mensagens de validação
   * do Bouncer da forma correta
   */
  prepareValidationStructure() {
    let allInputElements = this.getDOMNode().querySelectorAll(
      'input:not([type="radio"]), input:not([type="hidden"]), textarea',
    );

    allInputElements.forEach(input => {
      let inputName = input.getAttribute('name'),
        targetId = 'error-' + inputName;
      input.setAttribute('data-bouncer-target', `#${targetId}`);
      let errorMessageElement = document.createElement('div');
      errorMessageElement.id = targetId;
      errorMessageElement.classList.add('invalid-feedback');
      input.parentNode.appendChild(errorMessageElement);
    });
  }

  configureStaticFieldsMasks() {
    let fieldsSelectors = {
      '[type="tel"], .input-phone': MASK_TYPES.PHONE,
      '.input-money': MASK_TYPES.PRICE,
      '.input-km': MASK_TYPES.KM,
      '.input-plate': MASK_TYPES.PLATE,
      '.input-date': MASK_TYPES.DATE,
      '.input-number': [
        {
          mask: '0000',
        },
      ],
    };

    Object.keys(fieldsSelectors).forEach(function (maskSelector) {
      let fields = document.querySelectorAll(maskSelector);
      fields.forEach(function (el) {
        maskField(fieldsSelectors[maskSelector], el);
      });
    });
  }

  componentDidMount() {
    this.prepareValidationStructure();
    this.configureStaticFieldsMasks();

    const phoneMask = IMask(this.phoneInput, {
      mask: [...phoneMasks],
    });
    phoneMask.on('accept', () => this.setState({ phone: phoneMask.value }));

    this.validator = validationFactory(`#${this.props.formId}`);
  }

  render() {
    const {
      className,
      errorSendingForm,
      formId,
      onShowOverlay,
      isSubmittingForm,
      linkPrivacyPolicy,
      mainPhrase,
      showOverlay,
      titleForm,
      showLabels,
      showLocationFields,
      shouldValidateCpf,
    } = this.props;

    const customerTypeOptions = [
      { label: 'Pessoa física', value: PERSON_TYPES.INDIVIDUAL },
      { label: 'Pessoa jurídica', value: PERSON_TYPES.COMPANY },
    ];

    const isPessoaFisica =
      this.props.showCustomerType &&
      this.state.customerType === PERSON_TYPES.INDIVIDUAL;
    const isPessoaJuridica =
      this.props.showCustomerType &&
      this.state.customerType === PERSON_TYPES.COMPANY;
    const shouldShowCpfOnly =
      !this.props.showCustomerType && this.props.showCpf;

    return (
      <form
        className={classNames(
          'conversion-form form-conversion--static',
          className,
        )}
        ref={form => (this.form = form)}
        id={formId}
        onSubmit={this.handleSubmit}
        novalidate
      >
        <FormMessageOverlay
          handleClose={e => onShowOverlay(false)}
          isVisible={showOverlay}
          type={errorSendingForm ? 'error' : 'success'}
          successMessage={this.props.successMessage}
        />
        <header class="conversion-form__header-phrase">
          <h2 class="form-conversion__title">{titleForm}</h2>
          <p>{mainPhrase}</p>
        </header>
        <div class="form-conversion__body">
          {this.props.showCustomerType && (
            <div className="form-group">
              {showLabels && (
                <label className="conversion-form__control-label">
                  Tipo de cliente
                </label>
              )}
              <CustomSelect
                handleSelectChange={this.handleInputChange}
                name="customerType"
                options={customerTypeOptions}
                value={this.state.customerType}
                placeholder={false}
                shouldSort={false}
                searchEnabled={false}
              />
            </div>
          )}
          <div className="form-group">
            {showLabels && <label for="name">Nome</label>}
            <input
              onChange={this.handleInputChange}
              value={this.state.name}
              type="text"
              maxLength={80}
              className="form-control"
              name="name"
              required
              placeholder="Nome"
              data-bouncer-target="#invalid-name"
            />
            <div id="invalid-name" className="invalid-feedback" />
          </div>
          <div className="form-group">
            {showLabels && <label for="email">E-mail</label>}
            <input
              onChange={this.handleInputChange}
              value={this.state.email}
              type="email"
              maxLength={80}
              required={
                this.props.contactsWithDynamicRequired
                  ? this.state.mailing
                  : true
              }
              className="form-control"
              name="email"
              placeholder="E-mail"
              data-bouncer-target="#invalid-email"
            />
            <div id="invalid-email" className="invalid-feedback" />
          </div>
          <div className="form-group">
            {showLabels && <label for="phone">Telefone</label>}
            <input
              onChange={this.handleInputChange}
              value={this.state.phone}
              ref={phoneInput => (this.phoneInput = phoneInput)}
              className="form-control"
              name="phone"
              required={
                this.props.contactsWithDynamicRequired
                  ? this.state.phoning || this.state.whatsapping
                  : true
              }
              type="phone"
              autoComplete="off"
              data-bouncer-target="#invalid-phone"
              placeholder="Telefone/Whatsapp"
              data-should-validate={
                this.props.contactsWithDynamicRequired
                  ? String(this.state.phoning || this.state.whatsapping)
                  : 'true'
              }
            />
            <div id="invalid-phone" className="invalid-feedback" />
          </div>

          {isPessoaFisica && (
            <InputCpf
              handleChange={this.handleInputChange}
              value={this.state.cpf}
              label={showLabels ? 'CPF' : ''}
              shouldValidate={shouldValidateCpf}
            />
          )}

          {isPessoaJuridica && (
            <InputCnpj
              handleChange={this.handleInputChange}
              value={this.state.cnpj}
              label={showLabels ? 'CNPJ' : ''}
            />
          )}

          {shouldShowCpfOnly && (
            <InputCpf
              handleChange={this.handleInputChange}
              value={this.state.cpf}
              label={showLabels ? 'CPF' : ''}
              shouldValidate={shouldValidateCpf}
            />
          )}

          {showLocationFields && (
            <LocationFields
              handleStateChange={this.handleStateChange}
              uf={this.state.uf}
              handleCityChange={this.handleCityChange}
              city={this.state.city}
            />
          )}

          {this.props.versions && this.props.versions.length > 0 && (
            <div className="form-group">
              <CustomSelect
                handleSelectChange={this.handleInputChange}
                name="model_car"
                options={this.props.versions}
                value={this.state.model_car}
                shouldSort={false}
                placeholderValue="Escolha um modelo"
                searchEnabled={true}
              />

              {!this.state.versionValidStatus && (
                <div
                  id="invalid-version"
                  class="invalid-feedback is-invalid-version"
                >
                  <div class="error-message" id="bouncer-error_version">
                    Por favor, selecione esse campo
                  </div>
                </div>
              )}
            </div>
          )}

          {<StaticForm staticFormMarkup={this.props.staticFormMarkup} />}

          {this.props.chooseArea && this.props.categories.length > 1 && (
            <div className="form-group">
              <CustomSelect
                handleSelectChange={this.handleInputChange}
                name="category"
                options={this.props.categories}
                value={this.state.category}
                shouldSort={false}
                placeholderValue="Escolha a área"
                searchEnabled={false}
              />
            </div>
          )}

          {this.props.showUpload && (
            <div class="form-group">
              <InputFile
                onChange={this.handleInputFileChange}
                name="files"
                files={this.state.files}
                fileExtension={this.props.fileExtension}
                loading={this.state.sendingFile}
                error={this.state.uploadError}
              />
            </div>
          )}

          {!this.props.unit &&
            this.props.units.length > 1 &&
            this.props.showUnits && (
              <div className="form-group">
                <label className="conversion-form__control-label">
                  Escolha a unidade:
                </label>
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="unit"
                  options={this.props.units}
                  value={this.state.unit}
                  shouldSort={false}
                  placeholderValue="Escolha a unidade"
                  searchEnabled={false}
                />
              </div>
            )}

          <fieldset>
            <legend class="conversion-form__control-label">
              Quero receber contato por:
            </legend>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="mailing"
                value="true"
                onChangeCheckable={this.handleContactOptionsChange}
                isChecked={this.state.mailing}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                E-mail
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="whatsapping"
                value="true"
                isChecked={this.state.whatsapping}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Whatsapp
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="phoning"
                value="true"
                isChecked={this.state.phoning}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Telefone
              </CustomCheck>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control d-none"
                name="contact-options"
                required
                placeholder="Opções de contato"
                data-bouncer-target="#invalid-contact-options"
                value={this.state.contactOptions}
                checked={this.state.contactOptions}
              />
              <div id="invalid-contact-options" className="invalid-feedback" />
            </div>
          </fieldset>
          {this.props.shouldShowDataPermissions && (
            <fieldset className="mt-0 mb-2">
              <div className="form-check form-check-inline conversion-form__data-permissions-field">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={this.state.dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                  checkStyle={this.props.checkStyle}
                >
                  {this.props.dataPermissionsCustomText}
                </CustomCheck>
              </div>
            </fieldset>
          )}
          <footer className="form-conversion__footer">
            Ao informar meus dados, eu concordo com a{' '}
            <a href={linkPrivacyPolicy}>Política de privacidade</a>.
          </footer>
          <hr className="my-3"></hr>
          <SubmitButton
            classes="btn button button--large button--primary w-100 mb-0 button--submit"
            label={this.props.buttonText}
            isSubmitting={isSubmittingForm}
            handleClick={this.handleSubmit}
          />
        </div>
      </form>
    );
  }
}

StaticConversionForm.defaultProps = {
  onUpload() {},
  onStartSubmit() {},
  onStopSubmit() {},
  onErrorSubmit() {},
  onShowOverlay() {},
  category: 'Page',
  handleSubmit: function () {},
  errorSendingForm: false,
  checkStyle: 'light',
  categories: [],
  showUpload: false,
  fileExtension: '',
  units: [],
  showUnits: true,
  showCpf: false,
  shouldValidateCpf: false,
  showCustomerType: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showLocationFields: false,
  buttonText: 'ESTOU INTERESSADO',
  successMessage: 'Solicitação realizada com sucesso!',
};

StaticConversionForm.propTypes = {
  onUpload: PropTypes.func,
  onStartSubmit: PropTypes.func,
  onStopSubmit: PropTypes.func,
  onErrorSubmit: PropTypes.func,
  onShowOverlay: PropTypes.func,
  titleForm: PropTypes.string,
  mainPhrase: PropTypes.string,
  showOverlay: PropTypes.bool,
  bait: PropTypes.string,
  unit: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  product: PropTypes.string,
  model_car: PropTypes.number,
  brand: PropTypes.string,
  category: PropTypes.string,
  categories: PropTypes.array,
  price: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  isSubmittingForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  chooseArea: PropTypes.bool,
  showUpload: PropTypes.bool,
  fileExtension: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showCustomerType: PropTypes.bool,
  shouldValidateCpf: PropTypes.bool,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  showLabels: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  buttonText: PropTypes.string,
  successMessage: PropTypes.string,
};
