/*
 * Esse arquivo contém configurações padrão de validação de campos.
 * Também está disponível uma factory para o validador que é o que deve ser
 * utilizado para gerar um novo.
 */

import Bouncer from 'formbouncerjs/dist/bouncer.polyfills';
import { cpf } from 'cpf-cnpj-validator';
import { getChannelCountry } from './internacionalization';

export const defaultValidationOptions = {
  disableSubmit: true,
  fieldClass: 'is-invalid',
  customValidations: {
    phone(field) {
      if (
        field.getAttribute('type') === 'phone' &&
        field.dataset.shouldValidate !== 'false'
      ) {
        if (getChannelCountry() === 'PT') {
          return !/\+\d{3}\s\d{3}\s\d{3}\s\d{3}/.test(field.value);
        }

        return !/\(\d{2}\)\s\d{4,5}-?\d{4}/.test(field.value);
      }

      return false;
    },
    cpf(field) {
      const { toBool } = window.helpers;

      const isCpfField = field.getAttribute('name') === 'cpf';
      const cpfShouldValidate = toBool(field.dataset.shouldValidate);

      if (isCpfField && cpfShouldValidate) {
        const cpfIsValid = cpf.isValid(field.value);

        if (cpfIsValid) {
          return false;
        }

        return true;
      }

      return false;
    },
  },
  messages: {
    phone: 'Por favor, informe um telefone válido',
    cpf: 'Por favor, informe um CPF válido',
    date: 'Por favor, informe uma data válida',
    year: 'Por favor, informe um ano válido',
    missingValue: {
      checkbox: 'Esse campo é obrigatório',
      radio: 'Por favor, selecione uma opção',
      select: 'Por favor, selecione uma opção',
      'select-multiple': 'Por favor, selecione pelo menos um valor',
      default: 'Por favor, preencha esse campo',
    },
    patternMismatch: {
      email: 'Por favor, informe um e-mail válido',
      url: 'Por favor, informe uma URL',
      number: 'Por favor, informe um número',
      color: 'Por favor, escolha uma cor com o seguinte formato: #rrggb',
      date: 'Por favor, utilize uma data num formato válido',
      time: 'Por favor, utilize um formato de 24 hora',
      month: 'Por favor, utilize o formato YYYY-M',
      default: 'Por favor, preencha com o formato indicado',
    },
    outOfRange: {
      over: 'Por favor, selecione uma opção que é maior que {max}',
      under: 'Por favor, selecione uma opção que é menor que {min}',
    },
    wrongLength: {
      over: 'O tamanho máximo do texto é {maxLength}. Atualmente você tem {length} caracteres.',
      under:
        'O tamanho mínimo do texto é {minLength}. Atualmente você tem {length} caracteres.',
    },
  },
};

/**
 * Factory de validação de formulários.
 * @param {string} formSelector Seletor do form a ser validado
 * @param {*} options As [opções do Bouncer](https://github.com/cferdinandi/bouncer#options-and-settings)
 * @returns {Bouncer}
 */
export default function validationFactory(formSelector, options = {}) {
  return new Bouncer(formSelector, {
    ...defaultValidationOptions,
    ...options,
    customValidations: {
      ...defaultValidationOptions.customValidations,
      ...options.customValidations,
    },
  });
}
