import { makeApiV2Client } from './client/makeApiV2Client';

export class AutoServiceApiV2 {
  constructor() {
    this.client = makeApiV2Client();
  }

  getUsedModels(customParams) {
    const onlyActive = { active: true };
    const onlyUsedVehicles = { new_vehicle: false };

    const defaultParams = [onlyActive, onlyUsedVehicles];
    const params = defaultParams.concat(customParams);

    const endpoint = this._getChannelEndpoint('/used_models');

    return this.client.fetch(endpoint, params);
  }

  getUsedModelsSpecialOffers(customParams = []) {
    const onlyActive = { active: true };
    const onlyNewVehicles = { new_vehicle: true };

    const defaultParams = [onlyActive, onlyNewVehicles];
    const params = defaultParams.concat(customParams);

    const endpoint = this._getChannelEndpoint('/used_models');

    return this.client.fetch(endpoint, params);
  }

  getUsedModelsFullStock(customParams = []) {
    const onlyActive = { active: true };

    const defaultParams = [onlyActive];
    const params = defaultParams.concat(customParams);

    const endpoint = this._getChannelEndpoint('/used_models');

    return this.client.fetch(endpoint, params);
  }

  getUsedModelSimilarOffers(used_model_id) {
    let endpoint = this._getChannelEndpoint('/used_models');
    endpoint += `/${used_model_id}/suggestions`;

    return this.client.fetch(endpoint);
  }

  getClones(customParams = []) {
    const endpoint = this._getChannelEndpoint('/clones');

    return this.client.fetch(endpoint, customParams);
  }

  _getChannelEndpoint(suffixEndpoint) {
    const channelId = document.querySelector(
      "meta[name='auto_api_channel']",
    ).content;

    return `/${channelId}${suffixEndpoint}`;
  }
}
