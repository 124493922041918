import { h } from 'preact';
import { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CustomSelect from '#/CustomSelect';
import CustomCheck from '#/CustomCheck';
import SubmitButton from '#/SubmitButton';
import FormMessageOverlay from './FormMessageOverlay';
import handleInputChange from '%/utils/handleInputChange';
import handleUpdateState from '%/utils/handleUpdateState';
import validationFactory from '%/utils/validation';
import maskField, { MASK_TYPES } from '%/utils/maskField';
import getUTM from '%/utils/utm';
import { setRecaptchaLoadOnFormFocus } from '%/utils/reCAPTCHA';
import InputCpf from './InputCpf';
import InputCnpj from './InputCnpj';
import LocationFields from './Inputs/Location';

class ScheduleServiceForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.clearForm = this.clearForm.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleUpdateState = handleUpdateState.bind(this);
    this.handleTypeServiceChange = this.handleTypeServiceChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
  }

  resetState() {
    const today = new Date().toISOString().split('T')[0];

    this.setState({
      data: '',
      minDate: today,
      email: '',
      km: '',
      model_car: '',
      name: '',
      phone: '',
      plate: '',
      type_service: '',
      mailing: false,
      phoning: false,
      whatsapping: false,
      contactOptions: '',
      year: '',
      cpf: '',
      cnpj: '',
      customerType: 'pessoa-fisica',
      versionValidStatus: true,
      dataPermissions: false,
      uf: null,
      city: null,
      subsidiaries: [],
      subsidiary: '',
      subsidiaryDisabled: false,
    });
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf, subsidiary: '', subsidiaries: [] });
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({ city, subsidiary: '' });

    if (this.props.filterUnitsByLocation && this.state.uf && this.state.city) {
      this.setState({ subsidiaryDisabled: true });

      window.autoServiceApi
        .getServedRegion([
          { 'q[state_cont]': this.state.uf },
          { 'q[city_cont]': this.state.city },
        ])
        .then(res => {
          let subsidiaries = res.entries.map(region => ({
            label: region.display_name,
            value: region.display_name,
          }));

          const subsidiariesUniq = this.uniqByKeepFirst(
            subsidiaries,
            sub => sub.label,
          );

          this.setState({
            subsidiaries: subsidiariesUniq,
            subsidiary: '',
            subsidiaryDisabled: false,
          });
        });
    }
  }

  uniqByKeepFirst(a, key) {
    let seen = new Set();
    return a.filter(item => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  handleTypeServiceChange(e) {
    const { name, value } = e.target;

    const validate = this.state.type_service_is_valid !== undefined;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (validate) {
          this.validateTypeService();
        }
      },
    );
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf);
  }

  validateLocationFields() {
    if (!this.state.city) {
      document
        .getElementsByName('city')[0]
        .parentElement.classList.add('is-invalid');
      if (!this.state.uf) {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.add('is-invalid');
      } else {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.remove('is-invalid');
      }
    }
  }

  versionValid() {
    return this.state.model_car;
  }

  /**
   * Validação para select de serviço
   */
  validateTypeService() {
    const inputTypeService = this.form.querySelector(
      'input[type="hidden"][name="type_service"]',
    );

    if (!inputTypeService) return;

    this.setState({
      type_service_is_valid: this.validator.validate(inputTypeService)
        ? this.validator.validate(inputTypeService).valid
        : true,
    });
  }

  validateSubsidiary() {
    const elSubsidiarySelector = document.querySelector(
      '.subsidiary-select .choices__inner',
    );
    elSubsidiarySelector.classList.toggle('is-invalid', !this.state.subsidiary);
  }

  handleSubmit(e) {
    e.preventDefault();
    const validationResult = this.validator.validateAll(this.form),
      formIsValid = validationResult.length === 0;

    this.setState({ versionValidStatus: true });

    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return;
      }
    }

    if (this.props.filterUnitsByLocation && this.props.showLocationFields)
      this.validateSubsidiary();

    this.validateTypeService();
    if (this.props.showLocationFields) this.validateLocationFields();

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid() && this.state.customerType === 'pessoa-fisica') {
      alert('O campo de CPF é obrigatório');
      return;
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData()).then(() => {
      this.resetState();
    });
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      data: this.formatDate(this.state.data),
      email: this.state.email,
      km: this.state.km,
      mailing: this.state.mailing,
      model_car: this.state.model_car,
      name: this.state.name,
      phone: this.state.phone,
      phoning: this.state.phoning,
      plate: this.state.plate,
      type_service: this.state.type_service,
      whatsapping: this.state.whatsapping,
      year: this.state.year,
      customerType: this.state.customerType,
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf;
    }

    if (this.props.showCustomerType) {
      state.customerType = this.state.customerType;

      if (this.state.customerType === 'pessoa-juridica') {
        state.cnpj = this.state.cnpj;
      }
    }

    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions;
    }

    if (this.props.showLocationFields) {
      state.uf = this.state.uf;
      state.city = this.state.city;
    }

    let props = {
      bait: this.props.bait,
      channel: this.props.channel,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM(),
    };

    if (this.state.subsidiary) {
      state.subsidiary = this.state.subsidiary;
    }

    if (this.props.unit) {
      props['unit'] = this.props.unit;
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value;
    } else {
      props['unit'] = this.state.unit;
    }

    if (state['type_service']) {
      props['product'] = state['type_service'];
    }

    const scriptedData =
      (window.scheduleServiceFormScriptedData &&
        window.scheduleServiceFormScriptedData()) ||
      {};

    return { ...state, ...props, ...scriptedData };
  }

  formatDate(date) {
    const formattedDate = date.split('-').reverse().join('/');

    return formattedDate;
  }

  getCustomValidationOptions() {
    return {
      customValidations: {
        date(field) {
          const isDateField = field.getAttribute('name') === 'data';

          if (!isDateField) return false;

          if (field.value.length === 0) return true;

          const date = new Date(field.value);
          const minDate = new Date(field.min);

          const dateIsInvalid = date < minDate;

          return dateIsInvalid;
        },
        year(field) {
          const isYearField = field.getAttribute('name') === 'year';

          if (!isYearField) return false;

          const year = Number(field.value);

          const currentYear = new Date().getFullYear();
          const yearsBefore = 35;
          const yearsAfter = 1;

          const minYear = currentYear - yearsBefore;
          const maxYear = currentYear + yearsAfter;

          const yearIsInvalid = year < minYear || year > maxYear;

          return yearIsInvalid;
        },
      },
    };
  }

  // listagem de serviços
  componentDidUpdate() {
    const { hasScheduleServiceFormUpdate } = this.props;

    if (!hasScheduleServiceFormUpdate) return;

    window.scheduleServiceFormUpdate();
  }

  componentDidMount() {
    const customValidationOptions = this.getCustomValidationOptions();

    maskField(MASK_TYPES.PHONE, this.phoneInput, {}, maskValue =>
      this.handleUpdateState('phone', maskValue),
    );

    maskField(MASK_TYPES.KM, this.kmInput, {}, maskValue =>
      this.handleUpdateState('km', maskValue),
    );

    maskField(MASK_TYPES.YEAR, this.yearInput, {}, maskValue =>
      this.handleUpdateState('year', maskValue),
    );

    maskField(
      MASK_TYPES.PLATE,
      this.plateInput,
      {
        prepare(str) {
          return str.toUpperCase();
        },
      },
      maskValue => this.handleUpdateState('plate', maskValue),
    );

    this.validator = validationFactory(
      `#${this.props.formId}`,
      customValidationOptions,
    );

    if (this.props.loadRecaptchaOnMount) {
      setRecaptchaLoadOnFormFocus();
    }
  }

  clearForm(e) {
    const { hasScheduleServiceFormClear } = this.props;

    if (hasScheduleServiceFormClear) {
      window.scheduleServiceFormClear();
    }

    e.preventDefault();
    this.resetState();
  }

  render() {
    const customerTypeOptions = [
      { label: 'Pessoa física', value: 'pessoa-fisica' },
      { label: 'Pessoa jurídica', value: 'pessoa-juridica' },
    ];

    const isPessoaFisica =
      this.props.showCustomerType &&
      this.state.customerType === 'pessoa-fisica';
    const isPessoaJuridica =
      this.props.showCustomerType &&
      this.state.customerType === 'pessoa-juridica';
    const shouldShowCpfOnly =
      !this.props.showCustomerType && this.props.showCpf;

    return (
      <div>
        <form
          id={this.props.formId}
          className="form-modal"
          onSubmit={this.handleSubmit}
          ref={form => (this.form = form)}
        >
          <FormMessageOverlay
            handleClose={this.props.handleCloseOverlay}
            isVisible={this.props.showOverlay}
            type={this.props.errorSendingForm ? 'error' : 'success'}
          />
          <header class="form-modal__header">{this.props.titleForm}</header>
          <div className="form-modal__body">
            <header>
              Preencha o formulário abaixo e converse agora com um consultor:
            </header>
            <fieldset>
              {this.props.showCustomerType && (
                <div className="form-group">
                  <CustomSelect
                    handleSelectChange={this.handleInputChange}
                    name="customerType"
                    options={customerTypeOptions}
                    value={this.state.customerType}
                    placeholder={false}
                    shouldSort={false}
                    searchEnabled={false}
                  />
                </div>
              )}
              <div className="form-group">
                <input
                  type="text"
                  maxLength={80}
                  placeholder="Nome:"
                  name="name"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  required
                  data-bouncer-target="#invalid-name"
                />
                <div id="invalid-name" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  maxLength={80}
                  placeholder="E-mail:"
                  name="email"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.email}
                  required={
                    this.props.contactsWithDynamicRequired
                      ? this.state.mailing
                      : true
                  }
                  data-bouncer-target="#invalid-email"
                />
                <div id="invalid-email" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <input
                  type="phone"
                  placeholder="Telefone/WhatsApp:"
                  name="phone"
                  className="form-control"
                  ref={phoneInput => (this.phoneInput = phoneInput)}
                  value={this.state.phone}
                  required={
                    this.props.contactsWithDynamicRequired
                      ? this.state.phoning || this.state.whatsapping
                      : true
                  }
                  onChange={this.handleInputChange}
                  data-bouncer-target="#invalid-phone"
                  data-should-validate={
                    this.props.contactsWithDynamicRequired
                      ? String(this.state.phoning || this.state.whatsapping)
                      : 'true'
                  }
                />
                <div id="invalid-phone" className="invalid-feedback" />
              </div>

              {isPessoaFisica && (
                <InputCpf
                  handleChange={this.handleInputChange}
                  value={this.state.cpf}
                  updateState={value => this.handleUpdateState('cpf', value)}
                />
              )}

              {isPessoaJuridica && (
                <InputCnpj
                  handleChange={this.handleInputChange}
                  value={this.state.cnpj}
                  updateState={value => this.handleUpdateState('cnpj', value)}
                />
              )}

              {shouldShowCpfOnly && (
                <InputCpf
                  handleChange={this.handleInputChange}
                  value={this.state.cpf}
                  updateState={value => this.handleUpdateState('cpf', value)}
                />
              )}

              {this.props.showLocationFields && (
                <LocationFields
                  handleStateChange={this.handleStateChange}
                  uf={this.state.uf}
                  handleCityChange={this.handleCityChange}
                  city={this.state.city}
                />
              )}

              {this.props.versions && this.props.versions.length > 0 && (
                <div className="form-group">
                  <CustomSelect
                    handleSelectChange={this.handleInputChange}
                    name="model_car"
                    options={this.props.versions}
                    value={this.state.model_car}
                    shouldSort={false}
                    placeholderValue="Escolha um modelo"
                    searchEnabled={true}
                  />

                  {!this.state.model_car && !this.state.versionValidStatus && (
                    <div
                      id="invalid-version"
                      class="invalid-feedback is-invalid-version d-block"
                    >
                      <div class="error-message" id="bouncer-error_version">
                        Por favor, selecione esse campo
                      </div>
                    </div>
                  )}
                </div>
              )}

              {this.props.versions && this.props.versions.length <= 0 && (
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    value={this.state.model_car}
                    className="form-control"
                    name="model_car"
                    required
                    type="text"
                    data-bouncer-target="#invalid-model_car"
                    placeholder="Modelo"
                  />
                  <div id="invalid-model_car" className="invalid-feedback" />
                </div>
              )}

              <div className="form-group">
                <input
                  placeholder="Placa:"
                  name="plate"
                  className="form-control"
                  ref={plateInput => (this.plateInput = plateInput)}
                  value={this.state.plate}
                  type="plate"
                  required
                  onChange={this.handleInputChange}
                  data-bouncer-target="#invalid-plate"
                />
                <div id="invalid-plate" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <input
                  placeholder="Ano do modelo:"
                  name="year"
                  className="form-control"
                  value={this.state.year}
                  required
                  onChange={this.handleInputChange}
                  ref={yearInput => (this.yearInput = yearInput)}
                  data-bouncer-target="#invalid-year"
                />
                <div id="invalid-year" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <div class="input-group">
                  <input
                    placeholder="KM:"
                    name="km"
                    className="form-control"
                    ref={kmInput => (this.kmInput = kmInput)}
                    value={this.state.km}
                    required
                    onChange={this.handleInputChange}
                    data-bouncer-target="#invalid-km"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">KM</span>
                  </div>
                  <div id="invalid-km" className="invalid-feedback" />
                </div>
              </div>
              <div className="form-group">
                <label class="conversion-form__control-label" htmlFor="data">
                  Escolha a data:
                </label>
                <input
                  id="data"
                  placeholder="Data desejada:"
                  name="data"
                  ref={dateInput => (this.dateInput = dateInput)}
                  className="form-control"
                  value={this.state.data}
                  required
                  type="date"
                  onChange={this.handleInputChange}
                  min={this.state.minDate}
                  data-bouncer-target="#invalid-data"
                />
                <div id="invalid-data" className="invalid-feedback" />
              </div>

              {this.props.services && this.props.services.length > 0 && (
                <div
                  className={classNames('form-group', {
                    'form-group--error':
                      this.state.type_service_is_valid === false,
                  })}
                >
                  <label class="conversion-form__control-label">
                    Escolha o serviço:
                  </label>
                  <CustomSelect
                    handleSelectChange={this.handleTypeServiceChange}
                    name="type_service"
                    options={this.props.services}
                    value={this.state.type_service}
                    placeholderValue="Escolha o serviço"
                  />
                  <input
                    type="hidden"
                    value={this.state.type_service}
                    name="type_service"
                    className="form-control"
                    required
                    data-bouncer-target="#invalid-type_service"
                  />
                  <div id="invalid-type_service" className="invalid-feedback" />
                </div>
              )}

              {!this.props.unit &&
                this.props.units.length > 1 &&
                this.props.showUnits && (
                  <div className="form-group">
                    <div className="form-group">
                      <label className="conversion-form__control-label">
                        Escolha a unidade:
                      </label>
                      <CustomSelect
                        handleSelectChange={this.handleInputChange}
                        name="unit"
                        options={this.props.units}
                        value={this.state.unit}
                        shouldSort={false}
                        placeholderValue="Escolha a unidade"
                        searchEnabled={false}
                      />
                    </div>
                  </div>
                )}

              {this.props.filterUnitsByLocation &&
                this.props.showLocationFields && (
                  <div className="form-group subsidiary-select">
                    <div className="form-group">
                      <CustomSelect
                        handleSelectChange={this.handleInputChange}
                        name="subsidiary"
                        options={this.state.subsidiaries}
                        value={this.state.subsidiary}
                        shouldSort={false}
                        placeholderValue="Selecione sua concessionária"
                        searchEnabled={false}
                        disable={this.state.subsidiaryDisabled}
                      />
                    </div>
                  </div>
                )}
              {this.props.filterUnitsByLocation &&
                this.props.showLocationFields && (
                  <div className="form-group subsidiary-options">
                    <input
                      type="text"
                      className="form-control d-none"
                      name="subsidiary-options"
                      required
                      placeholder="Opções de Consessionaria"
                      data-bouncer-target="#invalid-subsidiary-options"
                      value={this.state.subsidiary}
                      checked={this.state.subsidiary}
                    />
                    <div
                      id="invalid-subsidiary-options"
                      className="invalid-feedback"
                    />
                  </div>
                )}
            </fieldset>
            <fieldset>
              <legend>Quero receber contato por:</legend>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="dark"
                  name="mailing"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.mailing}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  E-mail
                </CustomCheck>
              </div>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="dark"
                  name="whatsapping"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.whatsapping}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Whatsapp
                </CustomCheck>
              </div>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="dark"
                  name="phoning"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.phoning}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Telefone
                </CustomCheck>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control d-none"
                  name="contact-options"
                  required
                  placeholder="Opções de contato"
                  data-bouncer-target="#invalid-contact-options"
                  value={this.state.contactOptions}
                  checked={this.state.contactOptions}
                />
                <div
                  id="invalid-contact-options"
                  className="invalid-feedback"
                />
              </div>
            </fieldset>

            {this.props.shouldShowDataPermissions && (
              <fieldset>
                <div className="form-check form-check-inline conversion-form__data-permissions-field dark">
                  <CustomCheck
                    name="dataPermissions"
                    value="true"
                    isChecked={this.state.dataPermissions}
                    onChangeCheckable={this.handleInputChange}
                    type="checkbox"
                    checkStyle="dark"
                  >
                    {this.props.dataPermissionsCustomText}
                  </CustomCheck>
                </div>
              </fieldset>
            )}
            <fieldset className="consent-text mt-2">
              Ao informar meus dados, eu concordo com a{' '}
              <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>
              .
            </fieldset>
          </div>
          <footer>
            <a className="clear-fields" href="#" onClick={this.clearForm}>
              Limpar campos
            </a>
            <SubmitButton
              classes="btn form-modal__submit submit-button button button--primary mb-0"
              handleClick={this.handleSubmit}
              label="Agendar serviço"
              isSubmitting={this.props.isSubmitting}
            />
          </footer>
        </form>
      </div>
    );
  }
}

ScheduleServiceForm.defaultProps = {
  titleForm: 'Agendar serviço',
  handleSubmit() {},
  handleCloseOverlay() {},
  isSubmitting: false,
  services: [],
  product: '',
  channel: '',
  category: 'service',
  bait: '-2',
  units: [],
  showUnits: true,
  showCpf: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showLocationFields: false,
  subsidiaries: [],
  subsidiary: '',
  hasScheduleServiceFormUpdate: true,
  hasScheduleServiceFormClear: true,
  loadRecaptchaOnMount: false,
  showCustomerType: false,
};

ScheduleServiceForm.propTypes = {
  titleForm: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  services: PropTypes.array,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  bait: PropTypes.string,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  showCustomerType: PropTypes.bool,
  subsidiaries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  subsidiary: PropTypes.string,
  hasScheduleServiceFormUpdate: PropTypes.bool,
  hasScheduleServiceFormClear: PropTypes.bool,
  loadRecaptchaOnMount: PropTypes.bool,
};

export default ScheduleServiceForm;
