import { checkPattern } from './checkPattern';

export function checkPhonePattern() {
  const phonePattern =
    // eslint-disable-next-line no-useless-escape
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)((?:9?\d{4,5})\-?\d{4})$/;
  const phoneValidator = checkPattern(
    phonePattern,
    'Digite um telefone válido.',
  );

  return value => {
    const validationResult = phoneValidator(value);
    return validationResult;
  };
}
