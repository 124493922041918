import { Component } from 'preact';
import PropTypes from 'prop-types';
import CustomForm from './CustomForm';

class CustomFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {
        isSubmittingForm: false,
        errorSendingForm: false,
        showOverlay: false,
      },
      variants: [],
      previousOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleStartSubmit = this.handleStartSubmit.bind(this);
    this.handleStopSubmit = this.handleStopSubmit.bind(this);
    this.handleErrorSubmit = this.handleErrorSubmit.bind(this);
    this.handleShowOverlay = this.handleShowOverlay.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { open, renderAsModal } = this.props;

    if (renderAsModal) {
      if (open) {
        this.showModal();
      } else {
        this.closeModal();
      }
    }
  }

  showModal() {
    const modal = document.querySelector('.micromodal-slide#custom-form-modal');
    if (modal) {
      modal.classList.add('is-open');
      MicroModal.show(this.props.modalId);
    }
  }

  closeModal() {
    const modal = document.querySelector('.micromodal-slide#custom-form-modal');
    if (modal) {
      modal.classList.remove('is-open');
      MicroModal.close(this.props.modalId);
    }
  }

  handleUpload({ file }) {
    return window.serviceConversion.uploadFileS3(file);
  }

  handleStartSubmit() {
    this.startSubmit();
  }

  handleStopSubmit() {
    this.stopSubmit();
  }

  handleErrorSubmit(error) {
    this.errorSubmit(error);
  }

  handleShowOverlay(show) {
    this.showOverlay(show);
  }

  handleSubmit(e, params) {
    this.startSubmit();

    return window.serviceConversion
      .convert(params)
      .then(() => {
        this.errorSubmit(false);
      })
      .catch(error => {
        this.errorSubmit(true);
        console.error(error);
      })
      .finally(() => {
        this.stopSubmit();
        this.showOverlay(true);
      });
  }

  startSubmit() {
    this.setState({ formState: { isSubmittingForm: true } });
  }

  stopSubmit() {
    this.setState({ formState: { isSubmittingForm: false } });
  }

  errorSubmit(error) {
    this.setState({ formState: { errorSendingForm: error } });
  }

  showOverlay(show) {
    this.setState({
      formState: { showOverlay: show },
    });
  }

  componentWillMount() {
    this.props.cloneId &&
      service.getNewModelDetails(this.props.cloneId).then(res => {
        this.setState({
          variants: res.entries,
        });
      });
  }

  render() {
    const { isSubmittingForm, errorSendingForm, showOverlay } =
      this.state.formState;

    const formContent = (
      <CustomForm
        onUpload={this.handleUpload}
        onStartSubmit={this.handleStartSubmit}
        onStopSubmit={this.handleStopSubmit}
        onErrorSubmit={this.handleErrorSubmit}
        onShowOverlay={this.handleShowOverlay}
        handleSubmit={this.handleSubmit}
        isSubmittingForm={isSubmittingForm}
        errorSendingForm={errorSendingForm}
        showOverlay={showOverlay}
        variants={this.state.variants}
        formId={this.props.formId}
        mainTitle={this.props.mainTitle}
        mainSubtitle={this.props.mainSubtitle}
        successTitle={this.props.successTitle}
        successSubtitle={this.props.successSubtitle}
        submitButtonText={this.props.submitButtonText}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={this.props.units}
        unit={this.props.unit}
        customFormFields={this.props.customFormFields}
        switchableFields={this.props.switchableFields}
        shouldShowImage={this.props.shouldShowImage}
        shouldShowPrice={this.props.shouldShowPrice}
        shouldShowVariantSelect={this.props.shouldShowVariantSelect}
        shouldShowVariantName={this.props.shouldShowVariantName}
        defaultVariant={this.props.defaultVariant}
        product={this.props.product}
        channel={this.props.channel}
        category={this.props.category}
        categories={this.props.categories}
        brand={this.props.brand}
        cloneId={this.props.cloneId}
        bait={this.props.bait}
        module={this.props.module}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
      />
    );

    if (this.props.renderAsModal) {
      return (
        <div
          className="modal micromodal-slide"
          id={this.props.modalId}
          aria-hidden="true"
        >
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div className="position-relative">
              <div className="modal__content">
                <i
                  className="modal__close modal__overlay-close--form icon icon-close"
                  data-micromodal-close
                />
                {formContent}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return formContent;
  }
}

CustomFormApp.defaultProps = {
  mainTitle: 'Tenho interesse',
  mainSubtitle:
    'Preencha o formulário abaixo para receber o contato de um de nossos especialistas:',
  successTitle: 'Solicitação realizada com sucesso!',
  successSubtitle:
    'Em alguns instantes um de nossos consultores entrará em contato com você.',
  submitButtonText: 'ESTOU INTERESSADO',
  units: [],
  brand: '-',
  module: null,
  categories: [],
  customFormFields: [],
  switchableFields: {
    showEmailField: true,
    showPhoneField: true,
    showCpfField: false,
    showUnitField: false,
    showUploadField: false,
    showLocationField: false,
    showChooseContactMethod: true,
    showDataPermissions: true,
    showVariantSelect: false,
    showVariantImage: false,
    showVariantPrice: false,
    showPrice: false,
  },
  defaultVariant: '',
  dataPermissionsCustomText: null,
  linkPrivacyPolicy: null,
  product: null,
  channel: null,
  category: null,
  bait: null,
  unit: null,
  cloneId: null,
  renderAsModal: false,
  modalId: 'custom-form-modal',
  open: false,
};

CustomFormApp.propTypes = {
  formId: PropTypes.string.isRequired,
  mainTitle: PropTypes.string,
  mainSubtitle: PropTypes.string,
  successTitle: PropTypes.string,
  successSubtitle: PropTypes.string,
  submitButtonText: PropTypes.string,
  module: PropTypes.string,
  switchableFields: PropTypes.shape({
    showEmailField: PropTypes.bool,
    showPhoneField: PropTypes.bool,
    showCpfField: PropTypes.bool,
    showUnitField: PropTypes.bool,
    showUploadField: PropTypes.bool,
    showLocationField: PropTypes.bool,
    showChooseContactMethod: PropTypes.bool,
    showDataPermissions: PropTypes.bool,
    showVariantSelect: PropTypes.bool,
    showVariantImage: PropTypes.bool,
    showVariantPrice: PropTypes.bool,
    showPrice: PropTypes.bool,
  }),
  dataPermissionsCustomText: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  bait: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  unit: PropTypes.string,
  cloneId: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.string),
  customFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      field_type: PropTypes.string,
      title: PropTypes.string,
      placeholder_text: PropTypes.string,
      mask_type: PropTypes.string,
      attribute_name: PropTypes.string,
      field_options: PropTypes.string,
      is_required: PropTypes.bool,
      ordination: PropTypes.number,
    }),
  ),
  renderAsModal: PropTypes.bool,
  modalId: PropTypes.string,
  open: PropTypes.bool,
};

export default CustomFormApp;
